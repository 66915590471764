import React from 'react'
import './blogpost.scss';
import CardImage1 from "../../../assets/images/blog-post1.svg";
import CardImage2 from "../../../assets/images/blog-post2.svg";
import CardImage3 from "../../../assets/images/blog-post3.svg";
import { NavLink } from 'react-router-dom';
export default function BlogPost() {
    return (
        <div>
            <div className='blog-post-background'>
                <div className='container'>
                    <h1>New Blog Post</h1>
                    <div className='image-grid-card'>
                        <div className='sub-card-image'>
                            <img src={CardImage1} alt='cardimage' />
                            <p>Lorem ipsum </p>
                            <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
                            <NavLink to="/blogdetails"><h3>Read More</h3></NavLink>
                        </div>

                        <div className='sub-card-image'>
                            <img src={CardImage2} alt='cardimage' />
                            <p>Lorem ipsum </p>
                            <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
                            <NavLink to="/blogdetails"><h3>Read More</h3></NavLink>
                        </div>

                        <div className='sub-card-image'>
                            <img src={CardImage3} alt='cardimage' />
                            <p>Lorem ipsum </p>
                            <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
                            <NavLink to="/blogdetails"><h3>Read More</h3></NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
