import React from 'react'
import MobileAppDev from '../components/MobileAppDevComponent/MobileAppHead'
import MainContent from '../components/MobileAppDevComponent/detailsContentMobile'

export const MobileApp = () => {
  return (
    <div>
      <MobileAppDev/>
      <MainContent/>
    </div>
  )
}
