import './App.css';
import './scss/global.scss';
import { RouterProvider } from "react-router-dom";
import router from './route';
function App() {
  return (
    <RouterProvider router={router} />
  );
}
export default App;
