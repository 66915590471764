import React from 'react'
import BDHerobanner from '../components/BlogDetailComonent/BDHerobanner'
import BDbook from '../components/BlogDetailComonent/BDbook'
import BDsection from '../components/BlogDetailComonent/BDsectiontext'

export const BlogDetails = () => {
  return (
    <div>
        <BDHerobanner/>
        <BDbook/>
        <BDsection/>
    </div>
  )
}
