import React from 'react'
import './servicemain.scss';
import Services1 from "../../../assets/images/Services (1).svg";
import Services2 from "../../../assets/images/Services (2).svg";
import Services3 from "../../../assets/images/Services (3).svg";
import Services4 from "../../../assets/images/Services (4).svg";
import Services5 from "../../../assets/images/Service (5).svg";

import Arrow from "../../../assets/images/icons/Arrow 1.svg";
import { NavLink } from 'react-router-dom';
export default function MainGrid() {
    return (
        <div>
            <div className='background-services'>
                <div className='container'>
                    <div className='service-main-grid'>
                        <div className='service-sub-card'>
                            <img src={Services1} alt='Icon1' />
                            <h2>Mobile App Development</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. </p>
                            <NavLink to="/mobileapp" style={({ isActive }) => ({
                                color: isActive ? '#583FBB' : '#583FBB', textDecoration: "none"
                            })}><h3>Read More <img src={Arrow} alt='ArrowImage' /> </h3></NavLink>
                        </div>

                        <div className='service-sub-card'>
                            <img src={Services2} alt='Icon1' />
                            <h2>Fronted Development</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. </p>
                            <NavLink to="/mobileapp" style={({ isActive }) => ({
                                color: isActive ? '#583FBB' : '#583FBB', textDecoration: "none"
                            })}><h3>Read More <img src={Arrow} alt='ArrowImage' /> </h3></NavLink>
                        </div>

                        <div className='service-sub-card'>
                            <img src={Services3} alt='Icon1' />
                            <h2>Backend Development</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. </p>
                            <NavLink to="/mobileapp" style={({ isActive }) => ({
                                color: isActive ? '#583FBB' : '#583FBB', textDecoration: "none"
                            })}><h3>Read More <img src={Arrow} alt='ArrowImage' /> </h3></NavLink>
                        </div>

                        <div className='service-sub-card'>
                            <img src={Services4} alt='Icon1' />
                            <h2>Blockchain</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. </p>
                            <NavLink to="/mobileapp" style={({ isActive }) => ({
                                color: isActive ? '#583FBB' : '#583FBB', textDecoration: "none"
                            })}><h3>Read More <img src={Arrow} alt='ArrowImage' /> </h3></NavLink>
                        </div>

                        <div className='service-sub-card'>
                            <img src={Services5} alt='Icon1' />
                            <h2>UI/UX Design</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. </p>
                            <NavLink to="/mobileapp" style={({ isActive }) => ({
                                color: isActive ? '#583FBB' : '#583FBB', textDecoration: "none"
                            })}><h3>Read More <img src={Arrow} alt='ArrowImage' /> </h3></NavLink>
                        </div>

                        <div className='service-sub-card'>
                            <img src={Services3} alt='Icon1' />
                            <h2>Backend Development</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. </p>
                            <NavLink to="/mobileapp" style={({ isActive }) => ({
                                color: isActive ? '#583FBB' : '#583FBB', textDecoration: "none"
                            })}><h3>Read More <img src={Arrow} alt='ArrowImage' /> </h3></NavLink>
                        </div>

                        <div className='service-sub-card'>
                            <img src={Services1} alt='Icon1' />
                            <h2>Mobile App Development</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. </p>
                            <NavLink to="/mobileapp" style={({ isActive }) => ({
                                color: isActive ? '#583FBB' : '#583FBB', textDecoration: "none"
                            })}><h3>Read More <img src={Arrow} alt='ArrowImage' /> </h3></NavLink>
                        </div>

                        <div className='service-sub-card'>
                            <img src={Services2} alt='Icon1' />
                            <h2>Fronted Development</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. </p>
                            <NavLink to="/mobileapp" style={({ isActive }) => ({
                                color: isActive ? '#583FBB' : '#583FBB', textDecoration: "none"
                            })}><h3>Read More <img src={Arrow} alt='ArrowImage' /> </h3></NavLink>
                        </div>

                        <div className='service-sub-card'>
                            <img src={Services3} alt='Icon1' />
                            <h2>Backend Development</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. </p>
                            <NavLink to="/mobileapp" style={({ isActive }) => ({
                                color: isActive ? '#583FBB' : '#583FBB', textDecoration: "none"
                            })}><h3>Read More <img src={Arrow} alt='ArrowImage' /> </h3></NavLink>
                        </div>
                    </div>
                </div>
                <div className='border-bottom'></div>
            </div>
        </div>
    )
}
