import React from 'react'
import Heading from '../components/BlogComponent/blogHead'
import BlogHero from '../components/BlogComponent/blogHerobanner'
import BlogPost from '../components/BlogComponent/blogPost'
import Popular from '../components/BlogComponent/popularRes'

export const Blog = () => {
  return (
    <div>
      <Heading/>
      <BlogHero/>
      <BlogPost/>
      <Popular/>
    </div>
  )
}
