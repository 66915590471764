import React from 'react'
import './mobileAppContent.scss';
import HeroImage from '../../../assets/images/Rectangle blog-details-image.svg';
import CardImage1 from "../../../assets/images/blog-post1.svg";
import CardImage2 from "../../../assets/images/blog-post2.svg";
import CardImage3 from "../../../assets/images/blog-post3.svg";
import { NavLink } from 'react-router-dom';
export default function MainContent() {
    return (
        <div>
            <div className='container'>
                <div className='mobile-app-main'>
                    <img src={HeroImage} alt='Heroimage' />
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. Vestibulum faucibus mauris consectetur quam lacinia, non ultrices nisl sagittis. Nulla sit amet neque nibh. Nam at tempus nulla, non porta lectus. Duis ut porta massa. Nulla sit amet mattis elit. Sed tincidunt nulla vitae molestie congue. Vestibulum mattis purus justo, nec semper odio pretium vel. In sit amet condimentum arcu, in condimentum turpis. Aenean quis tellus sit amet nisl tempus pellentesque. Nunc enim libero, accumsan at nunc non, faucibus consectetur ex.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. Vestibulum faucibus mauris consectetur quam lacinia, non ultrices nisl sagittis. Nulla sit amet neque nibh. Nam at tempus nulla, non porta lectus. Duis ut porta massa. Nulla sit amet mattis elit. Sed tincidunt nulla vitae molestie congue. Vestibulum mattis purus justo, nec semper odio pretium vel. In sit amet condimentum arcu, in condimentum turpis. Aenean quis tellus sit amet nisl tempus pellentesque. Nunc enim libero, accumsan at nunc non, faucibus consectetur ex.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. Vestibulum faucibus mauris consectetur quam lacinia, non ultrices nisl sagittis. Nulla sit amet neque nibh. Nam at tempus nulla, non porta lectus. Duis ut porta massa. Nulla sit amet mattis elit. Sed tincidunt nulla vitae molestie congue. Vestibulum mattis purus justo, nec semper odio pretium vel. In sit amet condimentum arcu, in condimentum turpis. Aenean quis tellus sit amet nisl tempus pellentesque. Nunc enim libero, accumsan at nunc non, faucibus consectetur ex.</p>
                    <div className='main-grid-mob-app'>
                        <div className='sub-mob-app-image'>
                            <img src={CardImage1} alt='cardimage' />
                            <p>Lorem ipsum </p>
                            <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
                            <NavLink style={({ isActive }) => ({
                                color: isActive ? '#583FBB' : '#583FBB', textDecoration: "none"
                            })}><h3>Read More</h3></NavLink>
                        </div>

                        <div className='sub-mob-app-image'>
                            <img src={CardImage2} alt='cardimage' />
                            <p>Lorem ipsum </p>
                            <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
                            <NavLink style={({ isActive }) => ({
                                color: isActive ? '#583FBB' : '#583FBB', textDecoration: "none"
                            })}><h3>Read More</h3></NavLink>
                        </div>

                        <div className='sub-mob-app-image'>
                            <img src={CardImage3} alt='cardimage' />
                            <p>Lorem ipsum </p>
                            <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
                            <NavLink style={({ isActive }) => ({
                                color: isActive ? '#583FBB' : '#583FBB', textDecoration: "none"
                            })}><h3>Read More</h3></NavLink>
                        </div>
                    </div>
                    <div className='text-para-two'>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. Vestibulum faucibus mauris consectetur quam lacinia, non ultrices nisl sagittis. Nulla sit amet neque nibh. Nam at tempus nulla, non porta lectus. Duis ut porta massa. Nulla sit amet mattis elit. Sed tincidunt nulla vitae molestie congue. Vestibulum mattis purus justo, nec semper odio pretium vel. In sit amet condimentum arcu, in condimentum turpis. Aenean quis tellus sit amet nisl tempus pellentesque. Nunc enim libero, accumsan at nunc non, faucibus consectetur ex.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. Vestibulum faucibus mauris consectetur quam lacinia, non ultrices nisl sagittis. Nulla sit amet neque nibh. Nam at tempus nulla, non porta lectus. Duis ut porta massa. Nulla sit amet mattis elit. Sed tincidunt nulla vitae molestie congue. Vestibulum mattis purus justo, nec semper odio pretium vel. In sit amet condimentum arcu, in condimentum turpis. Aenean quis tellus sit amet nisl tempus pellentesque. Nunc enim libero, accumsan at nunc non, faucibus consectetur ex.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
