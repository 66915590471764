import React from 'react'
import './technology.scss';
import Tech1 from "../../assets/images/tech1.svg";
import Tech2 from "../../assets/images/tech2.svg";
import Tech3 from "../../assets/images/tech3.svg";
import Tech4 from "../../assets/images/tech4.svg";
export default function Technology() {
    return (
        <div>
            <div className='background-color'>
                <div className='container'>
                    <h2>TECHNOLOGY WE SERVE</h2>
                    <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc. Amet metus enim non ultricies turpis sit felis. A ipsum mollis natoque sodales eget ultrices id ultricies. In eu tellus lorem aenean urna. Eu dui amet felis at.</p>
                    <div className='grid-card-main'>
                        <div className='sub-card'>
                            <div className='image-flex'>
                                <img src={Tech1} alt='tech' />
                            </div>
                            <h3>Web</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text </p>
                        </div>

                        <div className='sub-card'>
                        <div className='image-flex'>
                                <img src={Tech2} alt='tech' />
                            </div>
                            <h3>IOS</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text </p>
                        </div>

                        <div className='sub-card'>
                        <div className='image-flex'>
                                <img src={Tech3} alt='tech' />
                            </div>
                            <h3>Android</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text </p>
                        </div>

                        <div className='sub-card'>
                        <div className='image-flex'>
                                <img src={Tech4} alt='tech' />
                            </div>
                            <h3>Iot</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
