import React from 'react'
import './blogherobanner.scss';
import RectangleImage from "../../../assets/images/Rectangle 10.svg";
import CardImage1 from "../../../assets/images/Rectangle 513.svg";
import CardImage2 from "../../../assets/images/Rectangle 514.svg";
import CardImage3 from "../../../assets/images/Rectangle 515.svg";
import CardImage4 from "../../../assets/images/Rectangle 516.svg";
import { NavLink } from 'react-router-dom';
export default function BlogHero() {
  return (
    <div>
        <div className='blog-hero-back-ground'>
            <div className='container'>
                <div className='main-grid-image'>
                    <div className='image-lg'>
                        <img src={RectangleImage} alt='heroImage' />
                        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. Vestibulum faucibus mauris consectetur quam lacinia, non ultrices nisl sagittis.</p>
                        <NavLink to="/blogdetails">
                        <button>Learn More</button>
                        </NavLink>
                        
                    </div>

                    <div className='image-md'>
                        <div className='text-flex'>
                            <div className='image-card'>
                                <img src={CardImage1} alt='card' />
                            </div>

                            <div className='text-card'>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. </p>
                                <NavLink to="/blogdetails"><h3>Read More</h3></NavLink>
                            </div>
                        </div>

                        <div className='text-flex'>
                            <div className='image-card'>
                                <img src={CardImage2} alt='card' />
                            </div>

                            <div className='text-card'>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. </p>
                            <NavLink to="/blogdetails"><h3>Read More</h3></NavLink>
                            </div>
                        </div>

                        <div className='text-flex'>
                            <div className='image-card'>
                                <img src={CardImage3} alt='card' />
                            </div>

                            <div className='text-card'>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. </p>
                            <NavLink to="/blogdetails"><h3>Read More</h3></NavLink>
                            </div>
                        </div>
                        
                        <div className='text-flex'>
                            <div className='image-card'>
                                <img src={CardImage4} alt='card' />
                            </div>

                            <div className='text-card'>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. </p>
                            <NavLink to="/blogdetails"><h3>Read More</h3></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
