import React from 'react'
import './BDHerobanner.scss';
import BlogDetailsImage from "../../../assets/images/Rectangle blog-details-image.svg";
export default function BDHerobanner() {
    return (
        <div>
            <div className='container'>
                <div className='heading-text'>
                    <p>Lorem ipsum </p>
                    <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h1>
                    <img src={BlogDetailsImage} alt='Details' />
                </div>
                <div className='text-paragraph'>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. Vestibulum faucibus mauris consectetur quam lacinia, non ultrices nisl sagittis. Nulla sit amet neque nibh. Nam at tempus nulla, non porta lectus. Duis ut porta massa. Nulla sit amet mattis elit. Sed tincidunt nulla vitae molestie congue. Vestibulum mattis purus justo, nec semper odio pretium vel. In sit amet condimentum arcu, in condimentum turpis. Aenean quis tellus sit amet nisl tempus pellentesque. Nunc enim libero, accumsan at nunc non, faucibus consectetur ex.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. Vestibulum faucibus mauris consectetur quam lacinia, non ultrices nisl sagittis. Nulla sit amet neque nibh. Nam at tempus nulla, non porta lectus. Duis ut porta massa. Nulla sit amet mattis elit. Sed tincidunt nulla vitae molestie congue. Vestibulum mattis purus justo, nec semper odio pretium vel. In sit amet condimentum arcu, in condimentum turpis. Aenean quis tellus sit amet nisl tempus pellentesque. Nunc enim libero, accumsan at nunc non, faucibus consectetur ex.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. Vestibulum faucibus mauris consectetur quam lacinia, non ultrices nisl sagittis. Nulla sit amet neque nibh. Nam at tempus nulla, non porta lectus. Duis ut porta massa. Nulla sit amet mattis elit. Sed tincidunt nulla vitae molestie congue. Vestibulum mattis purus justo, nec semper odio pretium vel. In sit amet condimentum arcu, in condimentum turpis. Aenean quis tellus sit amet nisl tempus pellentesque. Nunc enim libero, accumsan at nunc non, faucibus consectetur ex.</p>
                </div>
            </div>
        </div>
    )
}
