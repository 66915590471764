import React from 'react'
import './BDbook.scss';
import BlogDetailsBook from "../../../assets/images/Blog-details-book.svg";
import { NavLink } from 'react-router-dom';
export default function BDbook() {
    return (
        <div>
            <div className='black-background-bd'>
                <div className='container'>
                    <div className='main-grid-blog-details'>
                        <div className='bd-text'>
                            <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. Vestibulum faucibus mauris consectetur quam lacinia, non ultrices nisl sagittis.</p>
                            <NavLink><button>Learn More</button></NavLink>
                        </div>
                        <div className='bd-image'>
                            <img src={BlogDetailsBook} alt='book' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

