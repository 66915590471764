import React from 'react'
import './mainpart.scss';
import Mission from "../../../assets/images/mission-card-image.svg";
import Vision from "../../../assets/images/vision-card-image.svg";
export default function AboutMainPart() {
  return (
    <div>
        <div className='main-background-color-about'>
            <div className='container'>
                <div className='about-card-grid'>
                    <div className='about-sub-card'>
                        <img src={Mission} alt='mission' />
                        <h2>Our Mission</h2>
                        <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc. Amet metus enim non ultricies turpis sit felis. A ipsum mollis natoque sodales eget ultrices id ultricies. In eu tellus lorem aenean urna. Eu dui amet felis at.</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc. Amet metus enim non ultricies turpis sit felis. A ipsum mollis natoque sodales eget ultrices id ultricies. In eu tellus lorem aenean urna. Eu dui amet felis at.</p>
                    </div>

                    <div className='about-sub-card'>
                    <img src={Vision} alt='mission' />
                        <h2>Our Vision</h2>
                        <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc. Amet metus enim non ultricies turpis sit felis. A ipsum mollis natoque sodales eget ultrices id ultricies. In eu tellus lorem aenean urna. Eu dui amet felis at.</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc. Amet metus enim non ultricies turpis sit felis. A ipsum mollis natoque sodales eget ultrices id ultricies. In eu tellus lorem aenean urna. Eu dui amet felis at.</p>
                    </div>
                </div>
            </div>
            <div className='border-bottom'></div>
        </div>
    </div>
  )
}
