import React from "react";
import './footer.scss';
export default function Footer() {
  return (
    <div>
      <div className="footer-background">
        <div className="container">
          <h1>Get To Know Us!</h1>
          <div className="footer-text">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl.</p>
          </div>
          <div className="footer-main-grid">
            <div className="sub-grid-info">
              <div className="main-contact-info">
                <div className="sub-contact">
                  <p>hello@gmail.com</p>
                  <p>India: +91 1234567895</p>
                  <p>USA: +1 1234567895</p>
                </div>
                <div className="sub-contact">
                  <p>Lorem ipsum</p>
                  <p>India: +91 1234567895</p>
                </div>
              </div>
            </div>
            <div className="sub-grid-info"></div>
            <div className="sub-grid-info">
              <div className="main-contact-info">
                <div className="sub-contact">
                  <p>Why Us</p>
                  <p>FAQ</p>
                  <p>Insights</p>
                  <p>How we work </p>
                </div>
                <div className="sub-contact">
                  <p>Home</p>
                  <p>Our Work</p>
                  <p>Contact Us</p>
                  <p>Privacy Policy</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right">
          <p>© Copyright 2019 Techmohub. All Right Reserved</p>
        </div>
      </div>
    </div>
  )
}