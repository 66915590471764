import React from 'react'
import './herobanner.scss';
import HerobannerImage from "../../assets/images/icons/Asset 1 1.png";
import Arrow from "../../assets/images/icons/logo/4829869_arrow_next_right_icon 1.svg";
export default function Herobanner() {
    return (
        <div>
            <div className='hero-banner-main-grid'>
                <div className='sub-grid-text'>
                    <h1>
                        Empower Your Ideas with
                        <span> AI Collaboration</span>
                    </h1>
                    <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc. Amet metus enim non ultricies turpis sit felis. A ipsum mollis natoque sodales eget ultrices id ultricies. In eu tellus lorem aenean urna. Eu dui amet felis at. </p>
                    <button>Request A Free Quote <img src={Arrow} alt='arrowImage' /> </button>
                </div>

                <div className='sub-grid-image'>
                    <img src={HerobannerImage} alt='herobanner' />
                </div>
            </div>
        </div>
    )
}
