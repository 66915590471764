import React from 'react'
import './popular.scss';
import PopRes from "../../../assets/images/Rectangle pop (1).svg";
import Rectangle1 from "../../../assets/images/Rectangle pop1 (1).svg";
import Rectangle2 from "../../../assets/images/Rectangle pop2 (1).svg";
import Rectangle3 from "../../../assets/images/Rectangle pop3 (1).svg";
import { NavLink } from 'react-router-dom';
export default function Popular() {
  return (
    <div>
      <div className='popular-reso-background'>
        <div className='container'>
          <h1>Popular Resources</h1>
          <div className='popular-grid'>
            <div className='popular-sub-grid'>
              <div className='popular-flex'>
                <div className='popular-image'>
                  <img src={Rectangle1} alt='rectImage' />
                </div>

                <div className='popular-text'>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. </p>
                  <NavLink to="/blogdetails"><h3>Read More</h3></NavLink>
                </div>
              </div>
              <div className='popular-flex'>
                <div className='popular-image'>
                  <img src={Rectangle2} alt='rectImage' />
                </div>

                <div className='popular-text'>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. </p>
                  <NavLink to="/blogdetails"><h3>Read More</h3></NavLink>
                </div>
              </div>
              <div className='popular-flex'>
                <div className='popular-image'>
                  <img src={Rectangle3} alt='rectImage' />
                </div>

                <div className='popular-text'>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. </p>
                  <NavLink to="/blogdetails"><h3>Read More</h3></NavLink>
                </div>
              </div>
            </div>

            <div className='popular-sub-grid-img'>
              <img src={PopRes} alt='popularImage' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
