import React from 'react'
import './productmain.scss';
import Product1 from "../../../assets/images/Group product 1.svg";
import Product2 from "../../../assets/images/Group product 2.svg";
import Product3 from "../../../assets/images/Group product 3.svg";
export default function ProductMain() {
  return (
    <div>
        <div className='main-background-color-product'>
            <div className='container'>
                <div className='main-part-flex'>
                    <div className='sub-image-product'>
                        <img src={Product1} alt='Productimage1' />
                    </div>

                    <div className='sub-text-product'>
                        <h2>BOTIFY</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. Vestibulum faucibus mauris consectetur quam lacinia, non ultrices nisl sagittis. Nulla sit amet neque nibh. Nam at tempus nulla, non porta lectus. Duis ut porta massa. Nulla sit amet mattis elit. Sed tincidunt nulla vitae molestie congue. Vestibulum mattis purus justo, nec semper odio pretium vel. In sit amet condimentum arcu, in condimentum turpis. Aenean quis tellus sit amet nisl tempus pellentesque. Nunc enim libero, accumsan at nunc non, faucibus consectetur ex.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='main-background-color-product2'>
            <div className='container'>
                <div className='main-part-flex'>
                    <div className='sub-text-product'>
                        <h2>ASSIGNMENT AI</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. Vestibulum faucibus mauris consectetur quam lacinia, non ultrices nisl sagittis. Nulla sit amet neque nibh. Nam at tempus nulla, non porta lectus. Duis ut porta massa. Nulla sit amet mattis elit. Sed tincidunt nulla vitae molestie congue. Vestibulum mattis purus justo, nec semper odio pretium vel. In sit amet condimentum arcu, in condimentum turpis. Aenean quis tellus sit amet nisl tempus pellentesque. Nunc enim libero, accumsan at nunc non, faucibus consectetur ex.</p>
                    </div>

                    <div className='sub-image-product'>
                        <img src={Product2} alt='Productimage1' />
                    </div>
                </div>
            </div>
        </div>

        <div className='main-background-color-product'>
            <div className='container'>
                <div className='main-part-flex'>
                    <div className='sub-image-product'>
                        <img src={Product3} alt='Productimage1' />
                    </div>

                    <div className='sub-text-product'>
                        <h2>AI GYM</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam mi nisi, euismod ut leo in, volutpat dapibus nisl. Sed scelerisque nec nisi sit amet venenatis. Vestibulum faucibus mauris consectetur quam lacinia, non ultrices nisl sagittis. Nulla sit amet neque nibh. Nam at tempus nulla, non porta lectus. Duis ut porta massa. Nulla sit amet mattis elit. Sed tincidunt nulla vitae molestie congue. Vestibulum mattis purus justo, nec semper odio pretium vel. In sit amet condimentum arcu, in condimentum turpis. Aenean quis tellus sit amet nisl tempus pellentesque. Nunc enim libero, accumsan at nunc non, faucibus consectetur ex.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='border-bottom'></div>
    </div>
  )
}
