import React from 'react'
import './header.scss';
import CompanyLogo from "../../assets/images/icons/logo/botify AI_png_dark 1.svg";
import { NavLink } from 'react-router-dom';
export default function Header() {
  return (
    <div>
      <div className='header-grid'>
        <div className='header-logo'>
          <NavLink to="/"><img src={CompanyLogo} alt='Botify_Logo' /></NavLink>
        </div>
        <nav className='header-menu'>
          <NavLink to="/blog" >
            Blog
          </NavLink>
          <NavLink to="/services" >
            Services
          </NavLink>
          <NavLink to="/contact" >
            Contact
          </NavLink>
          <NavLink to="/about" >
            About
          </NavLink>
          <NavLink to="/product" >
            Product
          </NavLink>
        </nav>
        <div className='login-button'>
          <button>Login</button>
            <p>Sign Up</p>
        </div>
      </div>
    </div>
  )
}
