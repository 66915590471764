import React from 'react'
import './services.scss';
import Rectangle from "../../assets/images/icons/Rectangle 529.jpg";
import Group from "../../assets/images/icons/Group 8870.svg";
import Group1 from "../../assets/images/icons/Group.svg";
import Group2 from "../../assets/images/icons/Group (1).svg";
import Group3 from "../../assets/images/icons/Group (2).svg";
import Group4 from "../../assets/images/icons/Group (3).svg";
export default function Service() {
    return (
        <div>
            <div className='service-heading'>
                <div className='container'>
                    <h1>SERVICES</h1>
                    <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc. Amet metus enim non ultricies turpis sit felis. A ipsum mollis natoque sodales eget ultrices id ultricies. In eu tellus lorem aenean urna. Eu dui amet felis at.</p>
                </div>

                <div className='main-grid'>
                    <div className='sub-grid'>
                        <div className='position'>
                            <img src={Rectangle} alt='background-rectangle' />
                            <div className='group-image'>
                                <img src={Group} alt='Technology' />
                            </div>
                        </div>
                        <div className='card-heading'>
                            <h2>Mobile application development</h2>
                            <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc.</p>
                        </div>
                    </div>

                    <div className='sub-grid'>
                        <div className='position'>
                            <img src={Rectangle} alt='background-rectangle' />
                            <div className='group-image'>
                                <img src={Group1} alt='Technology' />
                            </div>
                        </div>
                        <div className='card-heading'>
                            <h2>Backend development</h2>
                            <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc.</p>
                        </div>
                    </div>

                    <div className='sub-grid'><div className='position'>
                        <img src={Rectangle} alt='background-rectangle' />
                        <div className='group-image'>
                            <img src={Group2} alt='Technology' />
                        </div>
                    </div>
                        <div className='card-heading'>
                            <h2>Frontend development</h2>
                            <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc.</p>
                        </div>
                    </div>
                {/* </div>

                <div className='main-grid'> */}
                    <div className='sub-grid'>
                        <div className='position'>
                            <img src={Rectangle} alt='background-rectangle' />
                            <div className='group-image'>
                                <img src={Group3} alt='Technology' />
                            </div>
                        </div>
                        <div className='card-heading'>
                            <h2>Blockchain</h2>
                            <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc.</p>
                        </div>
                    </div>

                    <div className='sub-grid'>
                        <div className='position'>
                            <img src={Rectangle} alt='background-rectangle' />
                            <div className='group-image'>
                                <img src={Group} alt='Technology' />
                            </div>
                        </div>
                        <div className='card-heading'>
                            <h2>Mobile application development</h2>
                            <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc.</p>
                        </div>
                    </div>

                    <div className='sub-grid'>
                        <div className='position'>
                            <img src={Rectangle} alt='background-rectangle' />
                            <div className='group-image'>
                                <img src={Group4} alt='Technology' />
                            </div>
                        </div>
                        <div className='card-heading'>
                            <h2>UI/UX design</h2>
                            <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc.</p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}
