import React from 'react'
import './project.scss';
export default function Project() {
    return (
        <div>
            <div className='main-background-color'>
                 <h2>GOT A PROJECT IN MIND?</h2>
                 <div className="project-text">
                 <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc. Amet metus enim non ultricies turpis sit felis. A ipsum mollis natoque sodales eget ultrices id ultricies. In eu tellus lorem aenean urna. Eu dui amet felis at.</p>
                 </div>
                 <button>Drop a Message</button>
            </div>
        </div>
    )
}
