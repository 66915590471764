import React from 'react'
import './contactform.scss';
export default function Form() {
  return (
    <div>
        <div className='conversation-background'>
            <div className='container'>
                <h1>Let’s Start a conversation</h1>
                <div className='main-grid-form'>
                    <div className='conver-text'>
                        <h2>Ask how we can help you:</h2>
                        <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc. Amet metus enim non ultricies turpis sit felis. A ipsum mollis natoque sodales eget ultrices id ultricies. In eu tellus lorem aenean urna. Eu dui amet felis at.</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Fusce lacus lorem porttitor enim sit quis nunc. Amet metus enim non ultricies turpis sit felis. A ipsum mollis natoque sodales eget ultrices id ultricies. In eu tellus lorem aenean urna. Eu dui amet felis at.</p>
                    </div>

                    <div className='conver-form'>
                        <div className='lable'>
                            First Name
                        </div>
                        <div className='input-name'>
                            <input type="text" placeholder='Enter First Name'/>
                        </div>

                        <div className='lable'>
                            Last Name
                        </div>
                        <div className='input-name'>
                            <input type="text" placeholder='Enter Last Name'/>
                        </div>

                        <div className='lable'>
                            Message
                        </div>
                        <div className='input-name1'>
                            <input type="text-area" placeholder='Start Typing Your message'/>
                        </div>
                        <button>Send</button>
                    </div>
                </div>
            </div>
            <div className='border-bottom'></div>
        </div>
    </div>
  )
}
