import React from 'react'
import ProductHead from '../components/ProductComponent/ProductHead'
import ProductMain from '../components/ProductComponent/ProductMainPart'

export const Product = () => {
  return (
    <div>
       <ProductHead/>
       <ProductMain/>
    </div>
  )
}
