import React from 'react'
import Servicehead from '../components/ServicesComponent/serviceHead'
import MainGrid from '../components/ServicesComponent/serviceMainContent'

export const Services = () => {
  return (
    <div>
        <Servicehead/>
        <MainGrid/>
    </div>
  )
}
