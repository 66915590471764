import React from 'react'
import './contacthead.scss';
import IconHome from "../../../assets/images/9104258_home_house_homepage_icon 1.svg";
import { NavLink } from 'react-router-dom';
export default function ContactHead() {
  return (
    <div>
       <div className='main-heading'>
        <div className='blur'></div>
        <h1>CONTACT</h1>
        <div className='flex-main'>
            <div className='icon'>
                <img src={IconHome} alt='HomeIcon' /> 
            </div>
            <div className='text-home'>
                <NavLink to="/" style={({ isActive }) => ({ 
                            color: isActive ? '#fff' : 'white' })}>
                    <p>Home</p>
                </NavLink>
            </div>
            <div className='text-blog'>
                <p>Contact</p>
            </div>
        </div>
       </div>
    </div>
  )
}
