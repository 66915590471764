import React from 'react'
import AboutHead from '../components/AboutComponent/AboutHead'
import AboutMainPart from '../components/AboutComponent/AboutMainPart'

export const About = () => {
  return (
    <div>
        <AboutHead/>
        <AboutMainPart/>
    </div>
  )
}
